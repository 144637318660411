<template>
    <div v-if="langswitchsThatMustBePresent.length >= 1 && modelLangButton === 'country'">
        <div class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}" v-if="langswitchsThatMustBePresent.length > 1 ">

            <lang-button tabindex="0" :name="currentNameBelgique" aria-label="Belgique - français" label="BE-FR" tolocale="fr_BE" v-if="langswitchsThatMustBePresent.includes('FR_BE')" :isActive="$i18n.locale === 'fr_BE'"></lang-button>
            <lang-button tabindex="0" :name="currentNameBelgie" aria-label="België - nederlands" label="BE-NL" tolocale="nl_BE" v-if="langswitchsThatMustBePresent.includes('NL_BE')" :isActive="$i18n.locale === 'nl_BE'"></lang-button>
            <lang-button tabindex="0" :name="currentNameBelgium" aria-label="Belgium - english" label="BE-EN" tolocale="en_BE" v-if="langswitchsThatMustBePresent.includes('EN_BE')" :isActive="$i18n.locale === 'en_BE'"></lang-button>

            <lang-button tabindex="0" :name="currentNameLuxembourg" aria-label="Luxembourg" label="LU-FR" tolocale="fr_LU" v-if="langswitchsThatMustBePresent.includes('FR_LU')" :isActive="$i18n.locale === 'fr_LU'"></lang-button>
            <lang-button tabindex="0" :name="currentNameFrance" aria-label="France" label="FR-FR" tolocale="fr_FR" v-if="langswitchsThatMustBePresent.includes('FR_FR')" :isActive="$i18n.locale === 'fr_FR'"></lang-button>

            <lang-button tabindex="0" :name="currentNameNederland" aria-label="Nederland" label="NL-NL" tolocale="nl_NL" v-if="langswitchsThatMustBePresent.includes('NL_NL')" :isActive="$i18n.locale === 'nl_NL'"></lang-button>

            <lang-button tabindex="0" :name="currentNameDeutschland" aria-label="Deutschland" label="DE-DE" tolocale="de_DE" v-if="langswitchsThatMustBePresent.includes('DE_DE')" :isActive="$i18n.locale === 'de_DE'"></lang-button>
            <lang-button tabindex="0" :name="currentNameSchweiz" aria-label="Schweiz" label="CH-DE" tolocale="de_CH" v-if="langswitchsThatMustBePresent.includes('DE_CH')" :isActive="$i18n.locale === 'de_CH'"></lang-button>
            <lang-button tabindex="0" :name="currentNameSuisse" aria-label="Suisse" label="CH-FR" tolocale="fr_CH" v-if="langswitchsThatMustBePresent.includes('FR_CH')" :isActive="$i18n.locale === 'fr_CH'"></lang-button>
            <lang-button tabindex="0" :name="currentNameEngland" aria-label="England" label="UK-EN" tolocale="en_UK" v-if="langswitchsThatMustBePresent.includes('EN_UK')" :isActive="$i18n.locale === 'en_UK'"></lang-button>

        </div>
    </div>

    <div v-else-if="langswitchsThatMustBePresent.length >= 1 && modelLangButton === 'language'">
        <div class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}" v-if="langswitchsThatMustBePresent.length > 1 ">
            <lang-button tabindex="0" :name="currentNameFR" aria-label="français" label="FR" tolocale="fr_BE" v-if="langswitchsThatMustBePresent.some(i=> ['FR_BE', 'FR_FR', 'FR_LU', 'FR_CH'].includes(i))" :isActive="$i18n.locale === 'fr_BE' || $i18n.locale === 'fr_FR' || $i18n.locale === 'fr_LU' || $i18n.locale === 'fr_CH'"></lang-button>
            <lang-button tabindex="0" :name="currentNameNL" aria-label="nederlands" label="NL" tolocale="nl_BE" v-if="langswitchsThatMustBePresent.some(i=> ['NL_BE', 'NL_NL'].includes(i))" :isActive="$i18n.locale === 'nl_BE' || $i18n.locale === 'nl_NL'"></lang-button>
            <lang-button tabindex="0" :name="currentNameDE" aria-label="deutsch" label="DE" tolocale="de_DE" v-if="langswitchsThatMustBePresent.some(i=> ['DE_BE', 'DE_DE', 'DE_CH'].includes(i))" :isActive="$i18n.locale === 'de_BE' || $i18n.locale === 'de_DE' || $i18n.locale === 'de_CH'"></lang-button>
            <lang-button tabindex="0" :name="currentNameEN" aria-label="english" label="EN" tolocale="en_BE" v-if="langswitchsThatMustBePresent.some(i=> ['EN_BE', 'EN_UK'].includes(i))" :isActive="$i18n.locale === 'en_BE' || $i18n.locale === 'en_UK'"></lang-button>
        </div>
    </div>

    <!-- DEFAULT lang in case of maintenance -->
    <div v-else class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}">
        <lang-button aria-label="français" :name="currentNameFR"  tabindex="0" label="FR" tolocale="fr_BE" :isActive="$i18n.locale === 'fr_BE'"></lang-button>
        <lang-button aria-label="nederlands" :name="currentNameNL" tabindex="0" label="NL" tolocale="nl_BE" :isActive="$i18n.locale === 'nl_BE'"></lang-button>
    </div>
</template>

<!-- ================================================================================== -->

<script>

    import LangButton from '@/components/LangButton';
    import Mixins from "../mixins/NonAutomatedData";

    export default {
        name:"langswitcherGDPR",

        props: {
            inHeader: {
                type: Boolean,
                required: false,
                default: true,
            }
        },

        components: {
            LangButton
        },
        mixins: [Mixins],

        data(){
            return {
                langswitchsThatMustBePresent: this.$store.getters.getLangList,
                currentNameFR: "Selecteer taal: frans",
                currentNameNL: "Selecteer taal: nederlands",
                currentNameDE: "Selecteer taal: duits",
                currentNameEN: "Selecteer taal: engels",
                currentNameBelgique: "Selecteer land: België - frans",
                currentNameBelgie: "Selecteer land: België - nederlands",
                currentNameBelgium: "Selecteer land: België - engels",
                currentNameNederland: "Selecteer land: Nederland",
                currentNameLuxembourg: "Selecteer land: Groot-Hertogdom Luxemburg",
                currentNameFrance: "Selecteer land: Frankrijk",
                currentNameDeutschland: "Selecteer land: Duitsland",
                currentNameSuisse: "Selecteer land: Zwitserland - frans",
                currentNameSchweiz: "Selecteer land: Zwitserland - duits",
                currentNameEngland: "Selecteer land: Engeland",
            }
        },

      methods: {
        checkLanguage(){
          if(this.$i18n.locale === "nl_BE"){
            this.currentNameFR = "Selecteer taal: frans";
            this.currentNameNL = "Selecteer taal: nederlands";
            this.currentNameDE = "Selecteer taal: duits";
            this.currentNameEN = "Selecteer taal: engels";
          }else if(this.$i18n.locale === "fr_BE"){
            this.currentNameFR = "Selectionner la langue: français";
            this.currentNameNL = "Selectionner la langue: néerlandais";
            this.currentNameDE = "Selectionner la langue: allemand";
            this.currentNameEN = "Selectionner la langue: anglais";
          }else if(this.$i18n.locale === "de_DE"){
            this.currentNameFR = "Sprache auswählen: Französisch";
            this.currentNameNL = "Sprache auswählen: Niederländisch";
            this.currentNameDE = "Sprache auswählen: Deutsch";
            this.currentNameEN = "Sprache auswählen: Englisch";
          }else if(this.$i18n.locale === "en_BE"){
            this.currentNameFR = "Select language: french";
            this.currentNameNL = "Select language: dutch";
            this.currentNameDE = "Select language: german";
            this.currentNameEN = "Select language: english";
          }
          return this.currentNameFR && this.currentNameNL && this.currentNameDE && this.currentNameEN;
        },
        checkCountry(){
          if(this.$i18n.locale === "nl_BE" || this.$i18n.locale === "nl_NL"){
            this.currentNameBelgique = "Selecteer land: België - frans";
            this.currentNameBelgie = "Selecteer land: België - nederlands";
            this.currentNameBelgium = "Selecteer land: België - engels";
            this.currentNameNederland = "Selecteer land: Nederland";
            this.currentNameLuxembourg = "Selecteer land: Groot-Hertogdom Luxemburg";
            this.currentNameFrance = "Selecteer land: Frankrijk";
            this.currentNameDeutschland = "Selecteer land: Duitsland";
            this.currentNameSuisse = "Selecteer land: Zwitserland - frans";
            this.currentNameSchweiz = "Selecteer land: Zwitserland - duits";
            this.currentNameEngland = "Selecteer land: Engeland";
          }else if(this.$i18n.locale === "fr_BE" ||this.$i18n.locale === "fr_LU" || this.$i18n.locale === "fr_FR" || this.$i18n.locale === "fr_CH"){
            this.currentNameBelgique = "Sélectionner le pays: Belgique - français";
            this.currentNameBelgie = "Sélectionner le pays: Belgique - néerlandais";
            this.currentNameBelgium = "Sélectionner le pays: Belgique - anglais";
            this.currentNameNederland = "Sélectionner le pays: Pays-Bas";
            this.currentNameLuxembourg = "Sélectionner le pays: Grand-Duché de Luxembourg";
            this.currentNameFrance = "Sélectionner le pays: France";
            this.currentNameDeutschland = "Sélectionner le pays: Duitsland";
            this.currentNameSuisse = "Sélectionner le pays: Suisse - français";
            this.currentNameSchweiz = "Sélectionner le pays: Suisse - allemand";
            this.currentNameEngland = "Sélectionner le pays: Angleterre";
          }else if(this.$i18n.locale === "de_DE" ||this.$i18n.locale === "de_CH"){
            this.currentNameBelgique = "das Land auswählen: Belgien - Französisch";
            this.currentNameBelgie = "das Land auswählen: Belgien - Niederländisch";
            this.currentNameBelgium = "das Land auswählen: Belgien - Englisch";
            this.currentNameNederland = "das Land auswählen: Niederlande";
            this.currentNameLuxembourg = "das Land auswählen: Großherzogtum Luxemburg";
            this.currentNameFrance = "das Land auswählen: Frankreich";
            this.currentNameDeutschland = "das Land auswählen: Duitsland";
            this.currentNameSuisse = "das Land auswählen: Schweiz - Französisch";
            this.currentNameSchweiz = "das Land auswählen: Schweiz - Deutsch";
            this.currentNameEngland = "das Land auswählen: England";
          }else if(this.$i18n.locale === "en_BE" ||this.$i18n.locale === "en_UK"){
            this.currentNameBelgique = "Select country: Belgium - french";
            this.currentNameBelgie = "Select country: Belgium - dutch";
            this.currentNameBelgium = "Select country: Belgium - english";
            this.currentNameNederland = "Select country: Netherlands";
            this.currentNameLuxembourg = "Select country: Grand Duchy of Luxembourg";
            this.currentNameFrance = "Select country: France";
            this.currentNameDeutschland = "Select country: Germany";
            this.currentNameSuisse = "Select country: Switzerland - french";
            this.currentNameSchweiz = "Select country: Switzerland - german";
            this.currentNameEngland = "Select country: England";
          }
        }

      },

      mounted(){
        this.checkLanguage();
        this.checkCountry();
      },

        watch: {
            // To be sure it load at startup
            '$store.state.langList': function(/* newLocale, oldLocale */){
                this.langswitchsThatMustBePresent = this.$store.getters.getLangList;
            },
        },
    }
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
