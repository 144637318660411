<template>
	<div id="layoutGen">

		<!-- HEADER-PAGE: full width top header -->
		<div id="header-page"></div>




		<!-- =============== FORMWRAPPER: full width, stretching block =============== -->
		<div id="formWrapper">

			<!-- CONTENTCOLUMN: The central Column -->
			<div id="contentColumn">

        <!-- HEADER: same width as the central column -->
				<div id="header-form" role="banner">
          <h1 style="position: absolute; top:-1000px; z-index: -10;">{{$t('formHeader.headerTitle')}}</h1>
          <div id="heroimage" :class="this.$i18n.locale.substring(0,2)" role="img" aria-hidden="true">


          <macaron v-if="false">
							<template v-slot:big><div v-html="$t('macaron.big')"></div></template>
							<template v-slot:small ><div v-html="$t('macaron.small')"></div></template>
						</macaron>	

					</div>
				</div>

        <!-- SKIP TO MAIN CONTENT BUTTON -->
        <span tabindex="-1" class="skipLinksWrapper" :class="{hiddenField : isNotVisible}"><a href="#section" role="button" class="skipLinks" :class="{hiddenField : isNotVisible}" @focus="isSkipLinkFocused();" @blur="isSkipLinkBlurred()">{{$t("pageLitteracy.skipToMainContent")}}</a></span>

				<!-- LANG -->
				<!-- inHeader="true" the lang buttons are placed in the top right inside the header -->
				<!-- inHeader="false" the lang buttons are placed outside and below the header (bottom right) -->
				<lang-switcher :inHeader="true" role="region"></lang-switcher>
        <!-- ========== FORMPAPER: all the pages ========== -->
				<div id="formPaper" role="main">
					<router-view></router-view>
				</div>

				<participation-counter 
				v-show="counterIsVisible" 
				counter-type="participationsLeft" 
				:displayed-number="lead.participations_left"
				></participation-counter>

				<!-- 
					<participation-counter 
					v-show="counterIsVisible" 
					counter-type="daysLeft" 
					:displayed-number="insert here a computed data (to be created)"
					></participation-counter> 
				-->


				<!-- ========== FOOTER-FORM: width of the central column ========== -->
				<div id="footer-form">
					<!-- <classic-footer></classic-footer> -->
				</div>
				<!-- ========== // ========== -->


			</div> <!-- End of #contentColumn -->
		</div> <!-- End of #formWrapper-->


		<!-- FOOTER-PAGE: full width bottom footer -->
		<div id="footer-page" role="contentinfo">
			<classic-footer v-if="!this.isPnGAction"></classic-footer>
			<pg-footer v-if="this.isPnGAction"></pg-footer>
		</div>

	</div> <!-- End of #layoutGen -->






	<!-- Custom GDPR Example -->
<!--
	<div class="gdpr-banner" :class="{visible : isGDPRVisible}">
		<div v-if="$i18n.locale ==='fr_BE' || $i18n.locale ==='fr_LU'">
			Ces données personnelles vous sont demandées dans le cadre de l’action cashback de CLIENT. Nous conserverons ces données pendant 6 mois pour la bonne exécution de cette action. Les données personnelles indiquées dans ce formulaire seront traitées par CLIENT (CLIENTGROUP) dans le cadre de l’action cashback, conformément à la <a :href="$t('footer.privacy.link')" target="_blank" rel="noopener">{{$t('footer.privacy.label')}}</a>. Si vous avez des questions concernant le traitement de vos données personnelles, veuillez nous contacter via le <a :href="$t('footer.contact.link')" target="_blank" rel="noopener">{{$t('footer.contact.label')}}</a>. Vous pouvez également utiliser ce formulaire en ligne si vous souhaitez vous opposer au traitement ou exercer vos autres droits.
		</div>
		<div v-else>
			Deze persoonsgegevens worden u gevraagd in het kader van de cashback actie van CLIENT. We bewaren deze gegevens 6 maanden voor het correct uitvoeren van deze actie. De persoonsgegevens zoals aangeven op dit formulier worden verwerkt door CLIENT (CLIENTGROUP) ten behoeve van de cashback actie conform het <a :href="$t('footer.privacy.link')" target="_blank" rel="noopener">{{$t('footer.privacy.label')}}</a>. Indien u vragen heeft omtrent de verwerking van uw persoonsgegevens, neem dan contact op via het <a :href="$t('footer.contact.link')" target="_blank" rel="noopener">{{$t('footer.contact.label')}}</a>. U kan dit online formulier ook gebruiken indien u bezwaar wenst te maken tegen de verwerking of om uw andere rechten uit te oefenen.
		</div>
		<div @click="isGDPRVisible = false;" class="gdpr-button">Ok</div>
	</div>
-->


<gdpr role="alertdialog" aria-label="GDPR"
v-if="!this.isPnGAction"
:client="$t('gdpr.client')"
:client-adress="leadGDPR.gdprstreet?.trim() + ' ' + leadGDPR.gdprstreetnum?.trim() + ', ' + leadGDPR.gdprcp?.trim() + ' ' + leadGDPR.gdprloc?.trim() + ' ' + leadGDPR.gdprcountry?.trim()"
contact-phone=""
:contact-fname="leadGDPR.gdprfname?.trim()"
:contact-name="	leadGDPR.gdprname?.trim()"
:contact-email="leadGDPR.gdprmail?.trim()"
@gdprClosing="insertPixel"
></gdpr>


<!-- OneTrust -->
<input v-if="this.isPnGAction" name="btnOneTrust" type="button" id="btnOneTrust" class="btnOneTrust" :value="$t('onetrust.cookie')" onclick="Optanon.ToggleInfoDisplay();">
</template>




<script>

	/* ======== COMPONENTS ======== */
	// import AppNav from '@/components/AppNav'; /* navigation between pages, not inside form */
	import GDPR from '@/components/GDPR.vue';
	import LangSwitcher from '@/components/LangSwitcher.vue';
	import Macaron from '@/components/Macaron.vue';
	import ClassicFooter from '@/components/ClassicFooter.vue';
	import PgFooter from '@/components/PgFooter.vue';
	import ParticipationCounter from '@/components/ParticipationCounter.vue';

	/* ======== MIXINS ======== */
	// import pixel from "./mixins/pixel";
	import ga from "./mixins/ga";
	import cf from "./mixins/cf";
	import mtm from "./mixins/mtm";
	import openPDF from "./mixins/openpdf";
	import customLog from "./mixins/CustomLog";
	import NonAutomatedData from "./mixins/NonAutomatedData";

	import Functions from "./mixins/Functions";
	import APICalls from "./mixins/APICalls";

	/* ======== OTHERS ======== */
	import config from "./config";


	export default {
		name: 'App',
		
		data() {
			return {
				idLead : config.idlead,
				lead: {},
				leadGDPR : {},
				isGDPRVisible : true,
				defaultLocale : "nl_BE",
				possibleLocales : [
					"fr_be", "nl_be", "fr_lu", "de_de", "en_be", 
					"en_uk", "fr_fr", "nl_nl", "fr_ch", "de_ch", 
					"de_lu", "it_it", "es_es", "pt_pt"],
        isNotVisible: true,
				/* isPnGAction: false /* ! TRUE if this is a PNG actions */
			}
		},
		
		components: {
			LangSwitcher,
			Macaron,
			ClassicFooter,
			'gdpr': GDPR,
			PgFooter,
			ParticipationCounter,
		},
		
		mixins: [
			ga,
			cf,
			mtm,
			customLog,
			openPDF,
			NonAutomatedData,
			Functions,
			APICalls,
			],


		methods: {
			getLocaleValidatedOrDefault(locale) {
				this.log("Regex testing", "low");
				const parts = locale.toLowerCase().split('_');
				const regex = /^[a-z]{2}$/;
				if (parts.length !== 2 || !regex.test(parts[0]) || !regex.test(parts[1])) {
					this.log(`This locale "${locale}" has a incorrect format`, "red");
					return this.defaultLocale;
				}else if(!this.possibleLocales.includes(locale.toLowerCase())) {
					this.log(`The locale "${locale}" is unknown or unauthorized`, "red");
					return this.defaultLocale;
				}else{
					this.log(`This locale "${locale}" has been validated`,"green");
					return parts[0] + '_' + parts[1].toUpperCase();
				}
			},

			getLangFromUrl () {
				this.log("Checking URL for locale or lang", "sep");

				this.$i18n.locale = this.defaultLocale;

				let urlParams = new URLSearchParams(window.location.search);
				let lang = '';
				let locale = '';

				if (urlParams.has('locale')){
					this.log("Getting locale from url", 'low');
					locale = urlParams.get('locale');
					this.$i18n.locale = this.getLocaleValidatedOrDefault(locale);
				}
				
				if (urlParams.has('lang')){
					this.log("Getting lang from url", 'low');
					lang = urlParams.get('lang');
					if (lang.toLowerCase() === "fr" || lang.toLowerCase() === "fr_be" || lang.toLowerCase() === "be_fr"){
						this.$i18n.locale = "fr_BE";
					}
				}
			},

			insertPixel() {
				/* INFO uncomment here to allow PIXEL insertion */
				// this.deployAndLog();
			},

			getGDPRData(idLead){
				this.log("Getting Leads for " + this.idLead, 'api');
				this.axios.get(
					`/leads/${idLead}`
					)
				.then(res => {
					this.log("I managed to get the lead for the GDPR", 'success');
					this.iGotTheGDPRLead(res);
				})
				.catch(error => {
					this.log("I cannot get the lead for the GDPR", 'alert');
					this.iCannottGetTheGDPRLead(error);
				})
				.then(()=>{
				});
			},

			iGotTheGDPRLead(res){
				this.log(res.data);
				this.leadGDPR = res.data;
				this.lead = res.data;
			},

			iCannottGetTheGDPRLead(error){
				console.log(error.response.data.errors);
				// this.displayModaleMessages(error.response.data.errors, 'alert');
				/* ? probably a redirection here is better : to show a adequat message. */
				/* TODO use the new errorsManager */
			},

      isSkipLinkFocused(){
        //console.log("focused");
        this.isNotVisible = false;
      },
      isSkipLinkBlurred(){
        //console.log("blurred");
        this.isNotVisible = true;
      },


		},
		

		beforeMount(){
			this.log("APP.VUE", "title");
			this.getLangFromUrl();
			this.getGDPRData(this.idLead);
		},

		mounted(){
			// Added for vue devtool to work — don't remove
			window.postMessage({ devtoolsEnabled: true, vueDetected: true });
		},

	}

</script>


<style lang="scss">

</style>



