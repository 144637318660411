<template>
  <div id="footerLogos" class="footerFixed">
    <ul class="pgFooterUl"  style="margin: 2em 0em 0em 0em">
      <li><img src="../assets/img/gillette_logo.png" alt="Gillette logo"></li>
      <li><img src="../assets/img/kingc_logo.png" alt="King C Gillette logo"></li>
      <li class="uefaLogoFooter"><img src="../assets/img/uefa_logo.png" alt="UEFA logo" class="uefaLogo">
        <p class="uefaDisclaimer"
           aria-label="Manufactured under license by Procter & Gamble. The word(s) UEFA, CHAMPIONS LEAGUE, and all other UEFA Champions League marks, names, logos, mascots and trophies are the property, registered trade marks, designs and/or copyright of UEFA. All rights reserved."
        >Manufactured under license by Procter & Gamble. The word(s) UEFA, CHAMPIONS LEAGUE, and all other UEFA Champions League marks, names, logos, mascots and trophies are the property, registered trade marks, designs and/or copyright of UEFA. All rights reserved.
        </p></li>
    </ul>
    <ul class="pgFooterUl" style="margin: 1em 0em 2.5em 0em">
      <li class="uefaLogoFooterMobile">
        <p class="uefaDisclaimer"
           aria-label="Manufactured under license by Procter & Gamble. The word(s) UEFA, CHAMPIONS LEAGUE, and all other UEFA Champions League marks, names, logos, mascots and trophies are the property, registered trade marks, designs and/or copyright of UEFA. All rights reserved."
      >Manufactured under license by Procter & Gamble. The word(s) UEFA, CHAMPIONS LEAGUE, and all other UEFA Champions League marks, names, logos, mascots and trophies are the property, registered trade marks, designs and/or copyright of UEFA. All rights reserved.
        </p></li>
    </ul>

  </div>
	<div id="classicFooter">

		<ul>
			<li>
				<a :href="$t('pgFooter.conditions')" @click.prevent="openPDFFile('conditions')" download tabindex="0">{{$t('pgFooter.conditions')}}</a>
			</li>
			<li v-if="$t('pgFooter.privacy.link').length > 0">
				<!-- Link must be specified in translations files to appears -->
				<a :href="$t('pgFooter.privacy.link')" target="_blank" rel="noopener" tabindex="0">{{$t('pgFooter.privacy.label')}}</a>
			</li>
			<li v-if="$t('pgFooter.otherconditions.link').length > 0">
				<!-- Link must be specified in translations files to appears -->
				<a :href="$t('pgFooter.otherconditions.link')" target="_blank" rel="noopener" tabindex="0">{{$t('pgFooter.otherconditions.label')}}</a>
			</li>

			<li v-if="$t('pgFooter.mycoords.link').length > 0">
				<!-- Link must be specified in translations files to appears -->
				<a :href="$t('pgFooter.mycoords.link')" target="_blank" rel="noopener" tabindex="0">{{$t('pgFooter.mycoords.label')}}</a>
			</li>
			<li v-if="$t('pgFooter.contact.link').length > 0">
				<!-- Link must be specified in translations files to appears -->
				<a :href="$t('pgFooter.contact.link')" target="_blank" rel="noopener" tabindex="0">{{$t('pgFooter.contact.label')}}</a>
			</li>
		</ul>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	import openPDF from "../mixins/openpdf";

	export default {
		name:"ClassicFooter",
		mixins: [openPDF],

    data() {
      return {
        reached: true,
      };
    },
    methods: {

      scrollIntoView() {

        let footerElement = document.getElementById("footerLogos");

        let observer = new IntersectionObserver(function(entries) {
          if(entries[0].intersectionRatio <= 0){
            console.log('Element has just become visible in screen');
            footerElement.classList.add("footerFixed");
          }else{
            footerElement.classList.remove("footerFixed");
          }
        }, { threshold: [0] });

        observer.observe(document.getElementById("footer-page"));

          /*let offsetTop = window.scrollY || 0;
          let percentage = Math.abs((offsetTop * 100) / document.body.clientHeight);

          if (percentage >= 62) {
            if (!this.reached) {

              this.reached = true;
            }
          } else{
            this.reached = false;
          }*/

      },
    },

    mounted(){
      this.scrollIntoView();
    },

	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
  .footerFixed{
    position: fixed;
    bottom: 0px;
    left: 0px;
  }
</style>
