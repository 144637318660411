<template>
  <div class="aSelectField">

    <!-- mask to close field if user click outside the field -->
    <div
        class="inputmask"
        v-if="isOptionsListOpen"
        @click="closeWithoutSelection"
    ></div>

    <div class="selectfield field">
      <label :for="fieldname"><span v-html="label"></span><span v-if="isRequired" class="req" tabindex="-1">&nbsp;*</span></label>

      <div class="dropdown" :class="{'open': isOptionsListOpen}">

        <input
            type="text"
            autocomplete="off"
            :id="fieldname"
            v-model="textInField"
            @input="userChangedInput"
            :placeholder = "placeholder"
            :class="{'hasSuccess': isInSuccess, 'hasError': isInError, 'isLoading': isLoading}"
            @keyup.prevent.down="keyPressedDown"
            @keyup.prevent.up = "keyPressedUp"
            @keyup.prevent.enter = "keyPressedEnter"
            :disabled="isDisabled"
            :aria-expanded="isOptionsListOpen"
            :aria-label="label"
            role="combobox"
            aria-controls="dropdown-list"
        >

        <!-- Toggler -->
        <div class="toggle" @click="toggleListOpeness()" @keyup.prevent.enter="toggleListOpeness()" role="button" tabindex="0" :class="{'active': isOptionsListOpen}" aria-controls="dropdown-list" :aria-expanded="isOptionsListOpen" aria-label="dropdown list button">
					<span class="arrow-up" v-show="isOptionsListOpen">
						<svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 viewBox="0 0 36 36" xml:space="preserve">
						<g>
							<polygon points="0,24 18,6 36,24 31,28 18,15 5,28"/>
						</g>
					</svg>
				</span>
          <span class="arrow-down" v-show="!isOptionsListOpen">
					<svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               viewBox="0 0 36 36" xml:space="preserve">
					<g>
						<polygon  points="5,8 18,21 31,8 36,12 18,30 0,12"/>
					</g>
				</svg>
			</span>
        </div>

        <!-- Options list -->
        <ul
            v-show="isOptionsListOpen"
            id="dropdown-list"
            class="options-list"
            tabindex="-1"
            role="listbox"
            aria-label="dropdown field">
          <li
              v-for="(option, index) in optionsList"
              :key="index"
              @click="selectThisOption(option, index)"
              :class="{'active': currentActiveOption === index}"
              tabindex="0"
              role="option"
              :aria-label="option.description"
          >{{option.description}}</li>
          <li v-show="noResult" @click="closeWithoutSelection">{{$t('inputLabel.noResult')}}</li>
        </ul>

      </div>
    </div>

    <div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
    <div class="helper"><span v-html="helper"></span></div>

  </div>
</template>

<!-- ================================================================================== -->

<script>
import FormFields from "../mixins/FormFields";
import customLog from "../mixins/CustomLog";

export default {
  name:"SelectFree",
  mixins: [
    customLog,
    FormFields,
  ],
  props: {
    label : {
      type: String,
      required: true, /* false in meta only */
    },
    dataArray : {
      /* only for free select field */
      type: Array,
      required: true,
    },
    caractersBeforeOpenList : {
      type: Number,
      required: false,
      default: 3
    },
    autocomplete: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data(){
    return {
      isOptionsListOpen: false,
      optionsList: [],
      displayedInTheField: null,
      noResult: false,
      currentActiveOption: null,
    }
  },
  computed: {
    textInField: {
      get(){
        return this.displayedInTheField;
      },
      set(newVal){
        this.displayedInTheField = newVal;
      }
    }
  },

  methods: {

    keyPressedDown(){
      if(this.isOptionsListOpen && this.currentActiveOption < this.optionsList.length -1){
        this.downOneItem();
      }
      if(!this.isOptionsListOpen){
        this.makeNewOptionsList(false);
        this.isOptionsListOpen = true;
      }
    },

    keyPressedUp(){
      if(this.isOptionsListOpen && this.currentActiveOption > 0){
        this.upOneItem();
      }else{
        this.closeOptionsList();
        this.currentActiveOption = null;
      }
    },

    keyPressedEnter(){
      if(this.isOptionsListOpen){
        this.log("Enter when list is open", 'low');
        this.selectThisOption(this.optionsList[this.currentActiveOption], this.currentActiveOption);
      }
    },

    upOneItem(){
      this.currentActiveOption -= 1;
    },

    downOneItem(){
      this.currentActiveOption += 1;
    },

    selectThisOption(option, index){
      this.log(`User choose item ${option.id} - ${option.description}`, 'low');
      this.displayedInTheField = option.description;
      this.currentActiveOption = index;
      this.closeOptionsList(option.id);
    },

    closeWithoutSelection(){
      this.log("Closing the optionList without selection", 'low');
      this.textInField = "";
      this.closeOptionsList();
      this.currentActiveOption = null; /* maybe could be inchanged instead? */
      this.runValidationProcess();
      this.$emit('update:modelValue', null);
    },

    toggleListOpeness(){
      this.isOptionsListOpen = !this.isOptionsListOpen;
      if(this.isOptionsListOpen){
        this.displayedInTheField = "";
        this.makeNewOptionsList(this.autocomplete);
      }
    },

    emptyAndCloseList(){
      this.optionsList = [];
      this.closeOptionsList();
    },

    userChangedInput(){
      /* Refresh list of options */
      /* User left no caracters => options list is empty */
      if(this.displayedInTheField.length === 0){
        this.emptyAndCloseList();
      }
      /* User type some caracters => options list matching strings */
      if(this.displayedInTheField.length >= this.caractersBeforeOpenList){
        this.makeNewOptionsList(this.autocomplete);
        if(!this.isOptionsListOpen){ this.openOptionsList(); }
      }
      /* Display "no result" instead of nothing */
      this.noResult = (this.displayedInTheField.length >= this.caractersBeforeOpenList && this.optionsList.length < 1);
    },

    makeNewOptionsList(bool){
      /* If autocomplete is true, it's a partial list */
      if(bool){
        let userInput = this.displayedInTheField.toLowerCase();
        this.optionsList = this.dataArray.filter(option => option.description.toLowerCase().includes(userInput));
      }
      /* If autocomplete is false, it's a full list */
      if(!bool){
        this.optionsList = this.dataArray;
      }
    },

    openOptionsList(){
      /* User open the option list */
      this.log("Opening option list", 'low');
      /* remove previous options */
      this.optionsList = [];
      /* recreate new options list */
      this.makeNewOptionsList(this.autocomplete);
      /* DOM open the list */
      this.isOptionsListOpen = true;
    },

    closeOptionsList(id){
      /* User leave the option list */
      this.noResult = false;
      this.log("Closing option list", 'low');
      this.isOptionsListOpen = false;
      this.runValidationProcess(id);
    },

    sendDataToForm(id){
      this.log(`SelectFree Field transmitting id ${id} to parent`, 'low');
      this.$emit('update:modelValue', id);
    },

    sendValidityOfTheFieldToTheForm(bool){
      this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
    },

    whatToDoInCaseFieldIsValidated(id){
      this.log("Field content approved", 'success');
      this.sendDataToForm(id);
      this.giveSuccess();
      this.sendValidityOfTheFieldToTheForm(true);
    },

    whatToDoInCaseFieldIsNotValidated(){
      this.log("Field content not approved", 'alert');
      this.errorMessage = this.$t('inputsTexts.dropdown.missingData');
      this.giveError();
      this.sendValidityOfTheFieldToTheForm(false);
    },

    runValidationProcess(id){
      this.removeSuccessOrError();
      /* If and answer is required, the field cannot be empty */
      if(this.isRequired && this.displayedInTheField.length > 0 || !this.isRequired && this.displayedInTheField.length > 0){
        this.whatToDoInCaseFieldIsValidated(id);
      }
      if(this.isRequired && !this.displayedInTheField.length > 0){
        this.whatToDoInCaseFieldIsNotValidated(id);
      }

      /* TODO User cannot easily place a choice that don't exist but I should check nevertheless is the answer is in the given options list. If autocomplete start at 3, I can type "xx" and it stays there. */
    }

  },

  watch: {
    /* Form will send a diffrent list in case of local change. Maybe I have to check here */
    /* TODO To be TESTED */
  },

  mounted(){
    /* If a value exist in the form (reparticipation), it must be loaded. */
    /* Could be === 0 witch is a falsy value => don't use !!this.modelValue */
    if(this.modelValue !== null && this.modelValue !== ''){
      this.log("There is a data in the form before user intervene (reparticipation).");
      let selectedOption = this.dataArray.filter(option => option.id === parseInt(this.modelValue));
      if(selectedOption[0]){
        this.displayedInTheField = selectedOption[0]?.description;
        this.runValidationProcess(selectedOption[0]?.id);
      }
      this.$emit('update:modelValue', parseInt(this.modelValue)); // parseInt the value received from the API, just in case
    }
  },
}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
